@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");

h1 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

h3 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 17px;
    margin-top: -4px;
}

p {
    font-family: "Poppins", sans-serif;
}

.App {
    touch-action: auto;
    scroll-behavior: auto;
    text-align: center;

}

#alert-dialog-title{
    color:#112A56;
}

.dialogTitle{
    color:#112A56;
    font-family:  "Poppins", sans-serif;
    font-size: 90%;
    font-weight: bold;
}

.loaderContent{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    vertical-align: center;
    margin-top: 20%;
}

.cardOrderUpdate {
    // border: 2px solid #30B595;
    min-height: 120px;
    margin: 0 auto;
    width: 80%;
    min-width: 350px;
    border-radius: 10px;
    margin-bottom: 25px;
    // box-shadow: 7px 7px 14px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 10px 3px rgba(153, 153, 153, 0.25);

    text-align: left;
}

.cardOrderUpdate:hover {
    background-color: #dbdbdb;
    transition: all 0.4s ease;
    cursor: pointer;   
}

.tableUpdateOrder{
    margin: 0 auto;
    width: 88vw;
    text-align: center;
    margin-bottom: 60px;
    max-width: 900px;
}

.thBase {
    width: 100%;
    // min-height: 40px ;
    // height: 60px;
    text-align: center;
    h4, p{
        padding: 0;
        margin: 0;
    }
}

.thHeader{
    color:rgb(0, 0, 0);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 3px;
    h4, p{
        padding: 0;
        margin: 0;
    }
}

.thContent {
    color:#000000;    
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    justify-content: center;
    h4, p{
        padding: 0;
        margin: 0;
    }
}

.thItemProduct {
    display: flex;
    align-items: center;
    height: 40px;
    text-overflow: ellipsis;
    justify-content: center;
}


.loader-content{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    vertical-align: center;
    margin-top: 50%;
    height: 60vh;
}
// ----------------------- Detalles de facturas
.bills-content-list {
    width: 100%;
    .content {
        display: grid;
        grid-template-columns: 10% 20% 30% 20% 20%;
        text-align: left;
        margin: auto;
        align-items: center;
    }
    .item-bill {
        padding: 5px 0;
        text-indent: 5px;
    }
    p,h4 {
        padding: 0;
        margin: 0;
    }
    h4 {
        color: #817a7a;
    }
    p{
        color: #7d7d7d;
    }
}
// .bills-content-list:hover {
//     background-color: #dbdbdb;
// }

.table-products {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 10% 10%  45%  10%  1fr  1fr;
    width: 100%;
}
.item-cell {
    border: 1px solid #000;
}
.item-details {
    width: 60%;
    margin: 0 auto;
    padding: 0;
}

// ----------------------- Detalles de facturas
.table-details-document {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}

.root-login {
    background-color: #f1f1f1;
    min-height: 100vh;
}

.header-login {
    h1 {
        font-weight: lighter;
    }

    span {
        font-weight: bold;
        color: #16366e;
    }

    h3 {
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    img {
        aspect-ratio: 1/1;
        width: 23%;
        max-width: 200px;
        margin-top: 30px;
    }
}

.content-login {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 60px auto 30px;
        
    .btn-app-bar {
        color: #f1f1f1;
        background-color: #16366e;
        min-width: 250px;
        max-width: 350px;
        height: 40px;
        font-weight: bold;
        margin: auto;
        border-radius: 10px;
        text-transform: capitalize;
    }
    .btn-app-bar:hover {
        background-color: #16366e;
        opacity: 0.8;
    }
}

.footer-login {
    display: flex;
    gap: 20px;
    width: 90%;
    justify-content: center;
    bottom: 0;
    margin: 20px auto 0px;
}

@media (min-width: 768px) {
    .header-login {
        img {
            aspect-ratio: 1/1;
            width: 35%;
            max-width: 140px;
            margin-top: 30px;
        }
    }
    .content-login {
        width: 25%;
        margin: 40px auto 10px;
    }
}


// @media screen and (max-width: 100) {
//     .header-login {
//         width: 40%;
//         img {
//             aspect-ratio: 1/1;
//             width:50%;
//             max-width: 600px;
//             margin-top: 30px;
//         }
//     }
//     .content-login  {
//         width: 40%;
//     }
// }